import './App.css';
import { useState } from 'react';

function readTextFile(url)
{
    var rawFile = new XMLHttpRequest();
    rawFile.open("GET", url, false);
    var allText = "empty";
    rawFile.onreadystatechange = function ()
    {
        if(rawFile.readyState === 4)
        {
            if(rawFile.status === 200 || rawFile.status === 0)
            {
                allText = rawFile.responseText;
                //alert(allText);
            }
        }
    }
    rawFile.send(null);
    return allText;
}

function readLinesFromFile(url) {
    return readTextFile(url).split('\n')
}

var podstatna_jmena = readLinesFromFile("podstatna_jmena.txt");

// this function returns a random element from an array
// it is used in the components to get a random word from the array
function getRandomWord(array) {
  return array[Math.floor(Math.random() * array.length)];
}

function getRandomDigit() {
    return Math.floor(Math.random() * 10);
}

function generatePassword() {
    var str = getRandomWord(podstatna_jmena) + "-" + getRandomWord(podstatna_jmena) + "-" + getRandomWord(podstatna_jmena) + "-" + getRandomDigit();
    return str;
}
function App() {
   const [password, setPassword] = useState(generatePassword());

   return (
    <div className="App">
      <header className="App-header">
        <h1>heslovač</h1>
            <h2>{password}</h2>
            <button onClick={() => setPassword(generatePassword)}>
                Generovat heslo
            </button>
      </header>
    </div>
  );
}

export default App;
